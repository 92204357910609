.root {
  @apply w-full mx-auto;
}
:global .slick-prev:before,
:global .slick-next:before {
  @apply text-black;
}
.root :global .slick-slider .slick-list {
  @apply mx-6;
}
.root :global .slick-slider .slick-prev {
  @apply -left-0.5;
}
.root :global .slick-slider .slick-next {
  @apply -right-0.5;
}
.root :global .slick-arrow {
  @apply min-w-0 text-[25px] leading-5 h-[25px] w-[25px];
}
:global .slick-arrow::before {
  display: none;
}
:global .slick-arrow svg {
  @apply text-secondary;
}
:global(.slick-slide) .content:hover .label,
:global(.slick-slide) .content.active .label {
  @apply text-primary;
}
.root :global(.slick-slide > div){
  @apply flex;
}
:global(.slick-slide) .content:hover .img svg,
:global(.slick-slide) .content.active .img svg{
  @apply !fill-primary;
}
.root :global(.slick-slide > div){
  @apply p-[2px];
}
.img {
  @apply w-auto text-center mx-[3px] max-w-[45px] mx-auto min-h-[32px] w-auto;
  /*h-[35px] w-[35px]*/
}
:global(.ringstyle) .img,
:global(.diamondshape) .img,
:global(.centerdiamondshape) .img {
  /*@apply !*h-[60px]*! rounded-full overflow-hidden my-1 mt-0.5;*/
}
:global(.ringstyle) .active > .img,
:global(.diamondshape) .active > .img,
:global(.centerdiamondshape) .active .img{
  /*@apply shadow-[0_0_0_2px] shadow-primary;*/
}
.label {
  @apply text-sm mt-[5px] font-medium;
}
.prevArrow,
.nextArrow {
  @apply !h-[25px] !w-[25px];
}
.active{
  @apply text-primary ;
}
.content{
  @apply cursor-pointer;
}
@media (pointer: coarse) {
  :global(.slick-slide) .content:not(.active):hover .img svg{
    @apply !fill-black;
  }
  :global(.slick-slide) .content:not(.active):hover .label{
    @apply text-black;
  }
}